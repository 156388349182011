<template>
  <div class="block">
    <div class="top">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index/template/list' }">模板管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑模板</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </div>
    <div class="content">
      <div class="box">
        <div class="img-box" :style="{'width':`${width}px`,'height':`${height}px`}">
          <img :src="url" alt="">
          <canvas ref="markCanvas"></canvas>
        </div>
      </div>
      <div class="right">
        <div class="list" v-for="(item,index) in markList" :key="index">
          <div class="title">
            <el-input
              size="small"
              placeholder="中文名称"
              v-model="item.title"
              style="width:270px">
            </el-input>
            <i class="el-icon-close" @click="delBlock(index)"></i>
          </div>
          <div class="btm">
            <el-input
              size="small"
              placeholder="字段名称"
              v-model="item.name"
              style="width:270px">
            </el-input>
            <el-checkbox v-model="item.is_time" style="margin-left:10px">是否时间</el-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { draw } from '../../assets/js/darw'
export default {
  name:"",
  data(){
    return {
       url:'',
       width: 0,
       height: 0,
       markList:[]
    }
  },
  created(){
    this.url = this.$route.query.url;
    this.getList(this.$route.query.id)
  },
  mounted(){
    this.width = this.$route.query.width;
    console.log(this.width)
    this.height = this.$route.query.height;
    this.initCanvas();
  },
  watch:{
    markList(val){
      // console.log(val)
    }
  },
  methods:{
    /* 画布初始化 */
    initCanvas() {
      let that = this
      this.$nextTick(() => {
          // 初始化canvas宽高
          let cav = this.$refs.markCanvas;
          cav.width = this.$route.query.width;
          cav.height = this.$route.query.height;
          let ctx = cav.getContext('2d');
          ctx.strokeStyle = 'blue'
          cav.style.cursor = 'crosshair'
          
          // 计算使用变量
          let list = this.markList; // 画框数据集合, 用于服务端返回的数据显示和绘制的矩形保存
          // 若服务端保存的为百分比则此处需计算实际座标, 直接使用实际座标可省略
          list.forEach(function (value, index, array) {
              let newValue = {
                  x: value.x,
                  y: value.y,
                  w: value.w,
                  h: value.h,
                  id: value.id ? value.id : '',
                  name: value.name ? value.name : '',
                  title: value.title ? value.title : '',
                  is_time: value.is_time ? value.is_time: false
              }
              list.splice(index, 1, newValue)
          })
          
          // 若list长度不为0, 则显示已标记框
          if (list.length !== 0) {
              list.forEach(function (value, index, array) {
                  // 遍历绘制所有标记框
                  ctx.rect(value.x, value.y, value.w, value.h);
                  ctx.stroke();
              });
          }
          
          // 调用封装的绘制方法
          draw(cav,list);

          // 备注: js中对象操作指向的是对象的物理地址, 获取绘制完矩形的结果数组直接取用或处理this.markList即可
      })
    },
    getList(id){
      this.$api.getBlockList({template_id	:id}).then(res =>{
        if(res.status == 200){
          res.data.lists.map(ele=>{
            ele.x = parseInt(ele.x)
            ele.y = parseInt(ele.y)
            ele.w = parseInt(ele.w)
            ele.h = parseInt(ele.h)
          })
          this.markList = [...res.data.lists]
          this.initCanvas();
        }
      })
    },
    delBlock(index){
      this.markList.splice(index,1)
      this.initCanvas();
    },
    onSubmit(){
      console.log(this.markList)
      let _this = this;
      this.markList.forEach(ele=>{
        ele.template_id	= _this.$route.query.id
      })
      this.$api.setBlockAdd(this.markList).then(res => {
        if(res.status == 200){
          this.$message({
            message: res.msg,
            type: 'success'
          });
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
.block{
  width: 100%;
  height: 100%;
}
.top{
  width: 100%;
  height: 60px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content{
  width: 100%;
  height: calc(100% - 40px);
  background: #eee;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  .box{
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-box{
      position: relative;
      img{
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        // height: 100%;
        z-index: 9;
      }
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
      }
    }
  }
  .right{
    width: 400px;
    height: 100%;
    background: #fff;
    overflow-y: scroll;
    padding: 12px;
    box-sizing: border-box;
    &::-webkit-scrollbar{
      display: none;
    }
    .list{
      width: 100%;
      // height: 100px;
      background: #eee;
      margin-bottom: 16px;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-icon-circle-close{
          margin-left: 10px;
        }
      }
      .btm{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>